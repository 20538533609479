@import "@/styles/functions";

.playButton {
  transition: transform 0.25s;

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}

.icon {
  width: toRem(75);
  height: toRem(75);

  @media (min-width: get-breakpoint-width("md")) {
    width: toRem(100);
    height: toRem(100);
  }
}
