@import '@/styles/functions';
@import '@/styles/new-variables';
@import '@/styles/variables';

.label {
  border-radius: toRem(12);
  background: $grey-1;
  padding: toRem(12);
  font-size: toRem(16);
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 19.5px */
  width: 100%;
  text-align: left;
  position: relative;

  &:hover {
    background: $grey-2;
  }
}

.textareaWrapper {
  height: 0;
  transition: height 0.3s;
  overflow: hidden;
  margin-bottom: toRem(12);

  &.active {
    margin: toRem(12) 0;
    height: toRem(116);

    .textarea {
      padding: toRem(12);
    }
  }
  .textarea {
    height: calc(100% - 4px);
    width: calc(100% - 2px);
    border-radius: toRem(12);
    color: $white;
    border: 1px solid $primary;
    background: $grey-1;
    font-size: toRem(20);
    transition: height 0.5s;
    overflow: hidden;
    outline: none;
    resize: none;

    &.hasError {
      border: 1px solid $red;
    }
  }

  .error {
    font-size: $form-input-error-font-size;
    color: $form-input-error-text-color;
  }
}
