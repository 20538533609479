@import '@/styles/functions';
@import '@/styles/new-variables';

.reportErrorWrapper {
  .reportModalHeader {
    color: $white;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
    border-bottom: 1px solid $transparent-white-6;
  }
}

div.modalCenterInMobile {
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
}
