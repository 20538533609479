@import "@/styles/functions";
@import "@/styles/new-variables";

$aspectRatio: 662/375;

.miniPlayerWrapper {
  position: relative;
  border-radius: toRem(20);
  border: toRem(1) solid $grey-10;
  padding-bottom: 56.6%;
  overflow: hidden;

  .playerContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // background: linear-gradient(180deg, rgba(15, 15, 18, 0.3) 0%, rgba(15, 15, 18, 0.8) 100%);
  }
}
