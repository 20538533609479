@import '@/styles/functions';
@import '@/styles/new-variables';

.breadcrumbsWrapper {
  display: flex;
  margin: toRem(12) 0;

  @media (max-width: get-breakpoint-width('lg')) {
    display: none;
  }
}
