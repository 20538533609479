@import "@/styles/functions";
@import "@/styles/new-variables";

.onlineTvPlayerControlPanel {
  width: 100%;

  .onlineTvActionButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flexSpacer {
      display: flex;
      align-items: center;
      gap: toRem(8);

      &.miniPlayer {
        gap: 0;
      }

      @media (min-width: get-breakpoint-width("lg")) {
        gap: toRem(12);
      }
    }
  }
}

.liveStreamBadge {
  display: flex;
  align-items: center;
  gap: toRem(4);
  color: $white;
  font-size: toRem(10);
  font-weight: 500;
  line-height: toRem(12);
  background-color: $transparent-white-10;
  border-radius: toRem(40);
  padding: toRem(8);
  margin-left: toRem(8);
  backdrop-filter: blur(50px);

  .dot {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $white;

    &::after {
      content: "";
      position: absolute;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: rgba(255, 77, 77, 1);
    }
  }

  @media (min-width: get-breakpoint-width("lg")) {
    font-size: toRem(12);
    line-height: toRem(15.6);
    padding: toRem(10);
    margin-left: toRem(12);
  }
}
