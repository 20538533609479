@import "@/styles/functions";
@import "@/styles/new-variables";

.mobilePoster {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: toRem(42);
  padding-bottom: toRem(24);
  background: linear-gradient(
    180deg,
    rgba(15, 15, 18, 0.3) 0%,
    rgba(15, 15, 18, 0.6) 25%,
    rgba(15, 15, 18, 0.96) 50%,
    #0f0f12 100%
  );
  border-bottom: 1px solid $grey-2;

  .topPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: toRem(12);

    .shareBtn {
      margin-left: auto;
    }
  }

  .title {
    // font-family: Inter;
    font-size: toRem(24);
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: toRem(20);
  }

  .ratingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: toRem(12);
    margin-bottom: toRem(12);

    .rating {
      display: flex;
      align-items: center;
      gap: toRem(8);
      // font-family: Inter;
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(16);
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  .genresWrapper {
    text-align: center;
    border-radius: toRem(10);
    padding: toRem(12);
    border: 1px solid $transparent-white-10;
    margin-bottom: toRem(20);
    font-size: toRem(12);
    font-weight: 400;
    line-height: toRem(15.6);
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $transparent-white-60;

    span {
      display: inline-block;
      margin-bottom: toRem(8);
    }

    .spacer {
      display: inline-block;
      vertical-align: middle;
      width: toRem(4);
      height: toRem(4);
      border-radius: 50%;
      background-color: $transparent-white-15;
      margin: 0 toRem(6);
    }
  }

  .buttonsWrapper {
    display: grid;
    grid-template-columns: toRem(44) calc(100% - toRem(112)) toRem(44);
    align-items: center;
    gap: toRem(12);

    .downloadBtn {
      width: toRem(44);
      height: toRem(44);
      padding: toRem(10);
    }
  }

  .backButton {
    width: toRem(44);
    height: toRem(44);
  }
}

.movieGenresWrapper {
  margin-top: toRem(12);
  margin-bottom: toRem(6);

  .title {
    color: $white;
    font-size: 1rem;
    font-weight: 600;
    line-height: toRem(19.36);
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: toRem(8);
  }

  .genreList {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.5rem;
    white-space: nowrap;
    padding-bottom: toRem(6);

    .genreCard {
      display: block;
      padding: toRem(6) toRem(8);
      color: $white;
      border-radius: toRem(6);
      background-color: $transparent-white-10;
      border: 1px solid #ffff4db2;
    }
  }
}
